import styled, { css } from 'styled-components';
import { ArtworkInfoButtonContainer } from '../ArtworkContent/styledElements';

const bgStyles = css`
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-size: contain;
  background-image: url(${({ imageBgUrl }) => imageBgUrl});

  @media all and (max-width: 480px) {
    background: none;
  }
`;

const ExhibitionFold = styled.section`
  width: calc(100% - 420px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: left;

  ${(props) => (props.imageBgUrl ? bgStyles : null)};

  h1 {
    letter-spacing: 0.05rem;
    font-size: 4.8rem;
    line-height: 5.4rem;
    color: #000;
    font-weight: 400;
  }

  h2 {
    letter-spacing: 0.05rem;
    font-size: 4.8rem;
    line-height: 5.4rem;
    color: #000;
    font-weight: 400;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    word-wrap: break-word;
    padding-top: 10px;
    font-family: 'Roboto';
    color: #000;
  }

  @media all and (max-width: 800px) {
    width: 100%;
    padding: 30px;

    h1,
    h2 {
      font-size: 2.4rem;
      line-height: 3rem;
    }

    p {
      font-size: 1.7rem;
    }
  }

  @media all and (max-width: 1600px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ExhibitionFoldsWrapper = styled.section`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media all and (max-width: 800px) {
    flex-wrap: wrap;
  }

  ${ExhibitionFold} {
    width: ${({ singleExhibition }) =>
      singleExhibition ? '100%' : 'calc(50% - 30px)'};
    margin: 0;
    min-height: 460px;
    justify-content: center;

    h1 {
      font-size: 3.3rem;
      line-height: 3.9rem;
    }

    h2 {
      font-size: 3.3rem;
      line-height: 3.9rem;
    }

    @media all and (max-width: 1600px) {
      width: ${({ singleExhibition }) =>
        singleExhibition ? '100%' : 'calc(50% - 30px)'};
    }

    @media all and (max-width: 800px) {
      min-height: 360px;
      width: 100%;

      &:nth-child(2n) {
        margin-top: 20px;
      }

      h1,
      h2 {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }

    @media all and (max-width: 500px) {
      min-height: 260px;
      width: 100%;

      &:nth-child(2n) {
        margin-top: 20px;
      }

      h1,
      h2 {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
  }
`;

const Content = styled.div`
  background-color: white;
  padding: 0 calc((100vw - 1920px) / 2);

  @media all and (max-width: 2340px) {
    padding: 0 210px;
  }

  @media all and (max-width: 1600px) {
    padding: 0;
  }
`;

const ExhibitionFoldContent = styled.div`
  background: #fff;
  max-width: 490px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 45px;

  @media all and (max-width: 800px) {
    padding: 20px;
  }

  @media all and (max-width: 480px) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.62);
  }

  h1 {
    font-weight: bold;
  }
`;

const EntryContainer = styled.div`
  padding: 0 calc((100vw - 1920px) / 2);

  @media all and (max-width: 2340px) {
    padding: 0 210px;
  }

  @media all and (max-width: 1600px) {
    padding: 0;
  }
`;

const AllArtworksContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 0 110px;

  @media all and (max-width: 800px) {
    padding: 0 20px;
  }
`;

const invisibleStyles = css`
  margin: 0;
  flex: 0 !important;
  z-index: -1;
  width: 0;
  height: 0;
  transform: scale(0);
  transition: all 0.2s;
`;

const ArtworkContainer = styled.div`
  flex: 1 0 33.33333%;
  max-width: 33.33333%;
  margin-bottom: 45px;
  ${({ visible }) => (visible ? null : invisibleStyles)};
  transition: all 0.2s;

  a {
    text-decoration: none;
  }

  @media all and (max-width: 1200px) {
    flex: 1 0 50%;
    max-width: 50%;
  }

  @media all and (max-width: 800px) {
    flex: 1 0 100%;
    max-width: 100%;
  }
`;

const ArtworkArtist = styled.p`
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #000000;
`;

const ArtworkTitle = styled.p`
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-style: italic;
  font-weight: bold;
  color: #3a3a3a;
`;

const ArtworkWrapper = styled.div`
  padding: 25px;
  margin-bottom: 1rem;
  border-radius: 2px;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.2s;
  }

  @media all and (max-width: 800px) {
    padding: 0 20px;
  }

  @media all and (max-width: 480px) {
    padding: 0;
  }
`;

const ArtworkImageHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 265px;

  @media all and (max-width: 700px) {
    height: 240px;
  }
  text-align: center;
  background: rgba(185, 185, 185, 0.3);
  margin-bottom: 10px;

  picture,
  img {
    max-height: 100%;
    max-width: 90%;
    width: auto;
    object-fit: cover;
  }

  p {
    line-height: 18px;
    padding-bottom: 5px;
  }
`;

const AllArtworksHeading = styled.h1`
  text-align: center;
  font-size: 4.8rem;
  line-height: 5.4rem;
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #000000;
`;

const VisualizationsWrapper = styled.section`
  position: relative;
`;

const VisualizationsInfo = styled.div`
  position: absolute;
  z-index: 10;
  top: 65px;
  left: calc(50% - 201px);
  height: 42px;
  width: 402px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: 'Roboto';
    font-weight: 300;
    text-align: center;
    font-size: 2rem;
    line-height: 2.6rem;
    color: #3a3a3a;
  }

  @media all and (max-width: 800px) {
    height: 36px;
    width: 340px;
    top: 20px;
    left: calc(50% - 170px);

    p {
      font-size: 1.7rem;
    }
  }

  @media all and (max-width: 360px) {
    p {
      font-size: 1.5rem;
    }
  }
`;

const VisualizationsThumbnails = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 0;
  width: calc(100% + 10px);
  margin-left: -5px;

  @media all and (max-width: 1600px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  a {
    display: block;
    text-align: center;
    flex-basis: 25%;

    @media all and (max-width: 800px) {
      flex-basis: 33%;
    }
    @media all and (max-width: 560px) {
      flex-basis: 50%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      padding: 5px;
      display: block;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
  ${({ hasMarginTop }) => (hasMarginTop ? 'margin-top: 50px;' : null)};
`;

const TooltipContainer = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 4px 0 rgba(64, 56, 46, 0.42);
  padding: 20px 30px;
  pointer-events: none;
  z-index: 1000;
  font-family: 'Roboto';

  @media all and (max-width: 800px) {
    display: none;
  }
`;

const TooltipArtist = styled.p`
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
`;

const TooltipTitle = styled.p`
  padding-bottom: 15px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 26px;
  color: #3a3a3a;
`;

const TooltipInfo = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  font-family: 'Roboto';
  color: #606060;
`;

const VideoWrapper = styled.div`
  margin-bottom: 25px;
  position: relative;
  height: 0;
  padding-top: 56.25%;
  width: 100%;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoSection = styled.section`
  padding: 20px 0;
  display: flex;
  flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};

  @media all and (max-width: 1600px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  @media all and (max-width: 800px) {
    flex-wrap: wrap;
  }
  > * {
    flex-basis: 50%;
    flex-grow: 1;

    @media all and (max-width: 800px) {
      flex-basis: auto;
    }
  }
`;

const VideoSectionWrapper = styled.section`
  display: flex;
  justify-content: space-between;

  ${VideoSection} {
    width: ${({ singleExhibition }) =>
      singleExhibition ? '100%' : 'calc(50% - 30px)'};
  }

  ${VideoWrapper} {
    margin-top: 20px;
  }

  @media all and (max-width: 800px) {
    flex-wrap: wrap;

    ${VideoSection} {
      width: 100%;
      &:nth-child(2n) {
        padding-top: 0;
      }
    }
  }
`;

const VideoContainer = styled.div`
  padding-top: 20px;
  display: flex;
  margin: auto;
  width: 70%;
}
  @media all and (max-width: 800px) {
    width: 100%;
  }
`;

const VideosWrapper = styled.div`
  display: flex;
  gap: 3rem;
  padding: 0 11rem 4rem;

  @media all and (max-width: 800px) {
    flex-direction: column;
    gap: 0;
    padding: 0px 2rem 4rem;
  }
`;

const VideoDescription = styled.div`
  padding-right: 60px;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 800px) {
    padding: 0 0 20px;
    width: 100%;
  }

  h1 {
    font-size: 4.2rem;
    font-weight: 400;
    line-height: 5rem;
    padding: 30px 0 10px;

    i {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    @media all and (max-width: 800px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
  p,
  i {
    color: #3a3a3a;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.8rem;
    + p {
      margin-top: 20px;
    }
  }
  p {
    margin-bottom: 20px;
    b {
      line-height: 2.8rem;
      font-size: 1.6rem;
    }
  }
`;

const AllArtworksFilters = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  @media all and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const AllArtworksFiltersButton = styled.button`
  display: block;
  background: none;
  font-size: 1.6rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid transparent;
  padding: 1rem 3rem;
  box-shadow: none;
  color: ${({ active }) => (active ? '#000;' : '#7D7D7D')};
  margin-right: 1.5rem;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: ${({ active }) => (active ? 'scaleX(1)' : 'scaleX(0)')};
    transition: all 0.2s;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: #000;
    &:after {
      transform: scaleX(1);
    }
  }

  @media all and (max-width: 600px) {
    margin-right: 0;
  }
`;

const Spacer = styled.div`
  height: ${({ height }) => height}px;
`;

const StyledHeaderLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 120px;
  display: block;
  //margin: -10px auto 10px auto;
  margin: 30px auto;
`;

const ArtworkButtonsContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.a`
  font-size: 1.7rem;
  font-weight: bold;
  color: #3a3a3a;
  margin-bottom: 5rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const BannerWrapper = styled.div`
  width: 81%;
  height: 23rem;
  background: rgb(234, 234, 234);
  margin: 3rem auto 8rem;

  @media all and (max-width: 1200px) {
    height: fit-content;
  }

  img {
    width: 17rem;
    height: 17rem;
  }

  @media all and (max-width: 800px) {
    img{
      width: 14rem;
      height: 14rem;
      margin: -19rem 0 6rem 0;
    }
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 3rem 8rem 3rem 4rem;
  justify-content: space-between;
  h1 {
    font-size: 2.3rem;
  }

  p {
    font-size: 1.6rem;
    padding: 1rem 0 2.5rem;
    width: 73rem;
    line-height: 2.6rem;

    @media all and (max-width: 1200px) {
      width: 90%;
      font-size: 1.5rem;
      line-height: 2.3rem;
    }

    @media all and (max-width: 800px) {
      width: 100%;
      padding: 1rem 0 20rem;
    }
  }

  @media all and (max-width: 800px) {
    display: block;
    text-align: center;
    padding: 3rem;
  }
`;
export {
  ExhibitionFold,
  EntryContainer,
  ArtworkImageHolder,
  AllArtworksContainer,
  AllArtworksHeading,
  ArtworkContainer,
  ArtworkWrapper,
  VisualizationsWrapper,
  VisualizationsInfo,
  VisualizationsThumbnails,
  ImageWrapper,
  ArtworkArtist,
  ArtworkTitle,
  TooltipInfo,
  TooltipTitle,
  TooltipArtist,
  TooltipContainer,
  VideoWrapper,
  VideoDescription,
  VideoSection,
  ExhibitionFoldContent,
  Content,
  ExhibitionFoldsWrapper,
  AllArtworksFilters,
  AllArtworksFiltersButton,
  Spacer,
  VideoSectionWrapper,
  VideoContainer,
  StyledHeaderLogo,
  ArtworkButtonsContainer,
  VideosWrapper,
  BannerWrapper,
  ContentWrapper,
  ButtonContainer,
};
