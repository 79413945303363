import React from 'react';
import AllArtworksWithFilter from './EntryContent/AllArtworksWithFilter';
import Footer from './Footer';
import Layout from './Layout';
import {
  Content,
  ExhibitionFoldsWrapper,
  VideoContainer,
  VideoDescription,
  VideoSection,
  VideoSectionWrapper,
  StyledHeaderLogo,
  ArtworkButtonsContainer,
  VideosWrapper,
  ButtonContainer,
} from './EntryContent/styledElements';
import Video from './EntryContent/Video';
import {
  ArtworkInfoButton,
  ArtworkInfoButtonContainer,
} from './ArtworkContent/styledElements';

const Entry = ({
  artworks,
  slug,
  assetsPath,
  cdnPath,
  exhibitions,
  visualizations,
  language,
  artworkSlugs,
}) => {
  const singleExhibition = exhibitions.length === 1;

  return (
    <Layout noImageBg>
      {/*      <Content>
        <Header assetsPath={assetsPath}/>
      </Content>*/}
      {/*      <AudioPlayer />*/}
      <ExhibitionFoldsWrapper singleExhibition={singleExhibition}>
        <StyledHeaderLogo
          src={`${assetsPath}/imperial-art-logotype.png`}
          alt='Imperial art logotype'
        />
        {/*        {exhibitions.map((exhibition) => (
          <ExhibitionFold imageBgUrl={assetsPath + '/home/'+ exhibition.home_page_image_file_name} key={exhibition.id}>
            <ExhibitionFoldContent>
              <StyledHeaderLogo
                src={`${assetsPath}/imperial-art-logotype.png`} alt="Imperial art logotype"
              />
              <h1>{exhibition.title}</h1>
              <h2>{exhibition.subtitle}</h2>
              <p style={{
                textAlign: 'center'
              }}>{exhibition.description}</p>
            </ExhibitionFoldContent>
          </ExhibitionFold>
        ))}*/}
      </ExhibitionFoldsWrapper>
      <Content>
        <VideoSectionWrapper singleExhibition={singleExhibition}>
          {exhibitions.map((exhibition) => (
            <VideoSection key={exhibition.id}>
              <VideoDescription>
                <h1>NFT sale of historical physical Napoleon’s objects</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: exhibition.home_page_info,
                  }}
                />
                <ButtonContainer
                  href='https://opensea.io/collection/napoleon-physical-objects-collection'
                  target='_blank'
                >
                  Click here to access the official OpenSea collection
                </ButtonContainer>
                <ArtworkButtonsContainer>
                  <ArtworkInfoButton
                    type={'secondary'}
                    as='a'
                    href={`/assets/documents/press/press-en.pdf`}
                    target='_blank'
                    centered
                  >
                    Press (EN)
                  </ArtworkInfoButton>
                  <ArtworkInfoButton
                    type={'secondary'}
                    as='a'
                    href={`/assets/documents/press/press-fr.pdf`}
                    target='_blank'
                    centered
                  >
                    Press (FR)
                  </ArtworkInfoButton>
                </ArtworkButtonsContainer>
                <ArtworkInfoButtonContainer>
                  {exhibition.press_file_name && (
                    <ArtworkInfoButton
                      as='a'
                      href={`${assetsPath}/documents/${exhibition.press_file_name}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      type='primary'
                      size='short'
                      centered
                    >
                      Press
                    </ArtworkInfoButton>
                  )}
                  {/*                  <ArtworkInfoButton
                    type="secondary"
                    onClick={() => scrollTo(`#all-artworks`)}
                  >Browse artworks</ArtworkInfoButton>*/}

                  {/*                  <ArtworkInfoButton
                      as="a"
                      href="https://opensea.io/collection/the-nautilus-room-collection"
                      target="_blank"
                      rel="noopener noreferrer"
                      type="primary"
                      size="short"
                      centered
                  >View The Nautilus Room NFT </ArtworkInfoButton>*/}
                </ArtworkInfoButtonContainer>
              </VideoDescription>
              {exhibition.video_url && (
                <VideoContainer>
                  <Video videoUrl={exhibition.video_url} />
                </VideoContainer>
              )}
            </VideoSection>
          ))}
        </VideoSectionWrapper>
        {/*        <Spacer height={30} />
          {exhibitions.map((exhibition) => (
            <Visualizations
              key={exhibition.id}
              visualizations={visualizations}
              artworks={artworks}
              slug={slug}
              assetsPath={assetsPath}
              cdnPath={`${cdnPath}/${exhibition.assets_folder}`}
              fillColor="rgba(255, 255, 255, 0.3)"
              strokeColor="rgba(255, 255, 255, 1)"
              language={language}
              hasSlider={false}
              isActive={exhibition.active}
            />
          ))}*/}
        <AllArtworksWithFilter
          artworks={artworks}
          assetsPath={assetsPath}
          cdnPath={cdnPath}
          exhibitions={exhibitions}
          artworkSlugs={artworkSlugs}
          language={language}
        />
        <VideosWrapper>
          <VideoContainer>
            <Video videoUrl={'https://www.youtube.com/embed/Yr7YDtWojbI'} />
          </VideoContainer>
          <VideoContainer>
            <Video videoUrl={'https://www.youtube.com/embed/RvOlTM43-vU'} />
          </VideoContainer>
        </VideosWrapper>

        {/* <BannerWrapper>
          <ContentWrapper>
            <div>
              <h1>Arteia NFC / NFT solution</h1>
              <p>
                An NFC chip, programmed by Arteïa and securely linked to the
                object and/or the paper certificate of authenticity provided by
                the gallery, makes it possible to link the object to its digital
                duplicate, recorded in a secure database on the blockchain. It
                is thus finally possible to sell the physical object via NFT
              </p>
              <ArtworkInfoButton
                type={'secondary'}
                as='a'
                href={`https://arteia.com/en/products/arteia-connect/?conf=1`}
                target='_blank'
                centered
              >
                Learn more
              </ArtworkInfoButton>
            </div>
            <img src='/assets/images/certificate-authenticity@2x.svg'></img>
          </ContentWrapper>
        </BannerWrapper> */}
        {exhibitions.map((exhibition) => (
          <Footer
            key={exhibition.id}
            exhibition={exhibition}
            assetsPath={assetsPath}
            cdnPath={cdnPath}
          />
        ))}
      </Content>
    </Layout>
  );
};

export default Entry;
