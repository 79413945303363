import React, { useState } from 'react';

import {
  AllArtworksHeading,
  ArtworkWrapper,
  ArtworkContainer,
  AllArtworksContainer,
  ArtworkImageHolder,
  ArtworkArtist,
  ArtworkTitle,
  AllArtworksFilters,
  AllArtworksFiltersButton,
} from './styledElements';
import { sliceTitle } from '../../helpers/sliceTitle';

const getExhibitionAssetFolderName = (exhibitions, exhibitionId) => {
  const foundExhibition = exhibitions.find(
    (exhibition) => exhibition.id === exhibitionId
  );
  if (foundExhibition !== undefined) {
    return foundExhibition.assets_folder;
  }
  console.error('Cannot find exhibition');
  return 'unknown-exhibition-asset-folder';
};

const AllArtworksWithFilter = ({
  artworks,
  assetsPath,
  cdnPath,
  artworkSlugs,
  language,
  exhibitions,
}) => {
  // const getUrl = (id, external_link) => {
  //     if (external_link && external_link !== 'x') {
  //         return external_link;
  //     }
  //     return `/${language}/${artworkSlugs[id]}`;
  // };

  const [activeFilter, setActiveFilter] = useState(0);
  const [artists] = useState([
    ...new Set(artworks.map(({ artist }) => artist)),
  ]);

  const onFilterClickHandler = (id) => {
    setActiveFilter(id);
  };

  const getUrl = (id) => {
    let slug = '';
    artworkSlugs.map((slugs) => {
      let res = slugs[id];
      if (res) slug = res;
    });
    // return `/${language}/${slug}`;
    return `/${slug}`;
  };

  return (
    <>
      <AllArtworksHeading id='all-artworks'>{'Artworks'}</AllArtworksHeading>
      <AllArtworksFilters>
        {/* NOTE: below we comment filtering options because currently only one artist is available */}
        {/*            <AllArtworksFiltersButton
                active={activeFilter === 0}
                onClick={() => onFilterClickHandler(0)}
            >
                All artworks
            </AllArtworksFiltersButton>
            {
                artists.map(
                    (artist, index) => (
                        <AllArtworksFiltersButton
                            active={index + 1 === activeFilter}
                            key={artist}
                            onClick={() => onFilterClickHandler(index + 1)}
                        >
                            {artist}
                        </AllArtworksFiltersButton>
                    )
                )
            }*/}
      </AllArtworksFilters>
      <AllArtworksContainer>
        {artworks.map(
          ({
            image_file_name,
            id,
            artwork_title,
            artist,
            external_link,
            exhibition_id,
          }) => (
            <ArtworkContainer
              key={id}
              visible={
                activeFilter === artists.indexOf(artist) + 1 ||
                activeFilter === 0
              }
            >
              <a href={getUrl(id)}>
                <ArtworkWrapper>
                  <ArtworkImageHolder>
                    <img
                      src={`${cdnPath}/${getExhibitionAssetFolderName(
                        exhibitions,
                        exhibition_id
                      )}/artworks/thumbnails/${image_file_name.split(';')[0]}`}
                      alt='preview'
                    />
                  </ArtworkImageHolder>
                  <div>
                    <ArtworkTitle>{sliceTitle(artwork_title)}</ArtworkTitle>
                  </div>
                </ArtworkWrapper>
              </a>
            </ArtworkContainer>
          )
        )}
      </AllArtworksContainer>
    </>
  );
};

export default AllArtworksWithFilter;
